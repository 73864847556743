import React from 'react';
import { profileColors } from "@constants";
import moment from 'moment';
import birthdayCake from '@content/images/icons/birthday-cake.png';
import { Tooltip } from '@mui/material';
import { ICheckinRecord, IStaffMember, ITimeslot, IUserPreferences } from '../definitions/interfaces';
import _ from 'lodash';

export const getProfileColor = () => {
  const colors: string[] = Object.keys(profileColors);
  const max = colors.length - 1;
  const randomKey = Math.floor(Math.random() * (max - 1 + 1) + 1);
  const colorKey = colors[randomKey];
  return profileColors[colorKey];
};

export const getAvatarProps = (name: string, size?: number, color?: string, src?: string) => {

  let isPng = false;
  try {
    if (src) {
      isPng = true;
    }
  }
  catch {

  }

  color = isPng ? '#fff' : color;

  if (!name) return {};
  name;
  return {
    sx: {
      width: size ? size * 3 : 40,
      height: size ? size * 3 : 40,
      fontSize: size || 12,
      background: color || '#3b82f6',
      alt: name,
    },
    children: `${name?.toUpperCase().split(' ')[0][0]}${name.split(' ')?.length > 1 ? name?.toUpperCase().split(' ')[1][0] : ''}`,
  };
};


export const staffExtensionData = [
  { label: 'IRD Number', name: 'ird_number', value: '' },
  { label: 'Department', name: 'department', value: '' },
  { label: 'Bank Account', name: 'bank_account', value: '' },
  { label: 'Staff Id', name: 'staff_id', value: '' },
  { label: 'Salary', name: 'salary', value: '' },
  { label: 'Home Address', name: 'address', value: '' },
  { label: 'Emergency Contact', name: 'emergency_contact', value: '' },
];

export const BirthdayIcon = (props: { dateOfBirth: Date | string | undefined; }): JSX.Element | null => {
  if (!props.dateOfBirth) {
    return null;
  }

  if (moment(props.dateOfBirth).format('DD/MM') === moment().format('DD/MM')) {
    return <Tooltip title="Birthday today"><img src={birthdayCake} alt="birthday" style={{ width: '25px', height: 'auto' }} /></Tooltip>;
  }

  return null;
};


export const getTotalHours = (checkIns: ICheckinRecord[]): string | null => {
  if (!_.isArray(checkIns)) {
    return null;
  }

  const minutes = checkIns.reduce((total, checkin) => {
    return total + moment(checkin.signed_out_at || checkin.updated_at).diff(moment(checkin.signed_in_at || checkin.created_at), 'minutes');
  }, 0);


  if (!minutes) {
    return null;
  }

  return (minutes / 60).toFixed(0) + ' hrs, ' + minutes % 60 + ' mins';
};

export type StaffHoursGrid = {
  daysWorked: number;
  hours: ICheckinRecord[];
  totalTime: string | null;
  staffMember: IStaffMember | undefined;
  rosteredTime: string | null;

}[];

export const buildTotalHoursGrid = (checkIns: ICheckinRecord[], weeklyRosteredTimes: ITimeslot[], staff: IStaffMember[]): StaffHoursGrid => {
  // use checkins to group by staff member and build a grid of total hours
  const staffIds = {};

  _.map(staff, (sm) => {
    staffIds[sm.id] = sm;
  });

  const checkinsGroupedByStaff = { ...staffIds, ..._.groupBy(checkIns, 'staff_user_id') };
  let gridTest = [];

  const staffGrid: StaffHoursGrid = _.map(Object.keys(checkinsGroupedByStaff), (staff_user_id) => {

    const rosteredTimeSlots = weeklyRosteredTimes.filter(timeslot => timeslot.staff_user_id === parseInt(staff_user_id));

    gridTest = gridTest.concat(_.map(rosteredTimeSlots, r => {
      return {
        staff_user_id: r.staff_user_id,
        start: moment(r.start).toLocaleString(),
        end: moment(r.end).toLocaleString(),
        staffMember: staffIds[r.staff_user_id],

      };
    }));

    const gridRow = {
      staff_user_id: parseInt(staff_user_id),
      start: moment(_.first(checkinsGroupedByStaff[staff_user_id])?.created_at).format('DD/MM/YYYY'),
      end: moment(_.last(checkinsGroupedByStaff[staff_user_id])?.created_at).format('DD/MM/YYYY'),
      staffMember: staffIds[staff_user_id],
    };
    gridTest.push(gridRow);

    return gridRow;
  });

  return _.sortBy(gridTest, g => [g.staffMember?.full_name]);
};


export const buildGroupedTotalHoursGrid = (checkIns: ICheckinRecord[], weeklyRosteredTimes: ITimeslot[]): StaffHoursGrid => {
  // use checkins to group by staff member and build a grid of total hours

  const staffGrid: StaffHoursGrid = _.map(checkIns, (checkIn) => {
    return {
      hours: checkIn,
      slug: checkIn.staff_member?.id,
      totalTime: 1,
      staffMember: checkIn.staff_member?.id,
    };
  });

  return staffGrid;
};

export const getWorkDays = (checkIns: ICheckinRecord[], timeslots: ITimeslot[]): number => {
  // lets get a count of days worked or days rostered, group by DD/MM, and count uniques 
  const timeslotDays = timeslots.map((timeslot) => moment(timeslot.start).format('DD/MM'));
  const checkinDates = _.groupBy(checkIns, (checkin) => moment(checkin.created_at!).format('DD/MM'));
  const checkinDays = Object.keys(checkinDates);

  return _.size(_.uniq([...timeslotDays, ...checkinDays]));
};


export const parseUserPreferences = (userPreferences: IUserPreferences): IUserPreferences | unknown => {
  try {
    let dashboard = JSON.parse(userPreferences?.dashboard ?? '{}');
    let parsedUserPreferences = {
      ...userPreferences,
      dashboard,
    };
    return parsedUserPreferences;
  } catch (e) {

    return {};
  }
}; 