import { SubscriptionPlanType } from "../definitions/enums"
import { ISubscriptionPlan } from "../definitions/interfaces"

export const primaryColor = "#3b82f6"

export const profileColors = {
  "81cacf": "#81cacf",
  "044f67": "#044f67",
  "7868e6": "#7868e6",
  f4bfbf: "#f4bfbf",
  "38ada9": "#38ada9",
  "8b76a5": "#8b76a5",
  "6c567b": "#6c567b",
  cd5d7d: "#cd5d7d",
  f47983: "#f47983",
  e5707e: "#e5707e",
  ea907a: "#ea907a",
  f6ae99: "#f6ae99",
  fbc687: "#fbc687",
  e8e9a1: "#e8e9a1",
  "5a8f7b": "#5a8f7b",
  b8e994: "#b8e994",
  "76ba99": "#76ba99",
  "182c61": "#182c61",
  "6ecf72": "#6ecf72",
  "6a83cd": "#6a83cd",
  "33994d": "#33994d",
  "2a4a7e": "#2a4a7e",
  "3c256f": "#3c256f",
  "2f838e": "#2f838e",
  "69349d": "#69349d",
  "993392": "#993392",
  c453c6: "#c453c6",
  "5b93c8": "#5b93c8",
}

export const defaultGridOptions = {
  defaultColDef: {
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    resizable: true,
    sidebar: true,
  },
  rowHoverHighlight: true,
  // suppressMenuHide: true,
  debug: true,
  groupDisplayType: "groupRows",
  overlayNoRowsTemplate: "No data found",
}

// Small -      $29 per month / billed annually
//              $33 per month / billed monthly
//              Up to 20 members

// Medium -     $49 per month / billed annually
//              $54 per month / billed monthly
//              Up to 30 members

// Large -      $120 per month / billed annually
//              $130 per month / billed monthly
//              Unlimited members (not sure if this should be unlimited or up to 100?)

export const LiveSubscriptionPlans: ISubscriptionPlan[] = [
  {
    name: "Small",
    features: ["Up to 10 members"],
    priceId: "price_1NTL9iC13CZ7WmqGtqMRdFKw",
    price: 34,
    type: SubscriptionPlanType.small,
    billingPeriod: "monthly",
  },
  {
    name: "Small",
    features: ["Up to 10 members"],
    priceId: "price_1NTL9iC13CZ7WmqGjhTf3xEy",
    price: 348,
    type: SubscriptionPlanType.small,
    billingPeriod: "annually",
  },
  {
    name: "Medium",
    features: ["Up to 20 members"],
    priceId: "price_1NTLCeC13CZ7WmqGi2Ip2y7n",
    price: 39,
    type: SubscriptionPlanType.medium,
    billingPeriod: "monthly",
  },
  {
    name: "Medium",
    features: ["Up to 20 members"],
    priceId: "price_1NTLCeC13CZ7WmqGMTEMBCfo",
    price: 408,
    type: SubscriptionPlanType.medium,
    billingPeriod: "annually",
  },
  {
    name: "Medium Large",
    features: ["Up to 30 members"],
    priceId: "price_1PUf5XC13CZ7WmqG2C7CUYGd",
    price: 588,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: "annually",
  },
  {
    name: "Medium Large",
    features: ["Up to 30 members"],
    priceId: "price_1OQ4MfC13CZ7WmqGBRHBOhB5",
    price: 54,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: "monthly",
  },
  {
    name: "Large",
    features: ["Up to 50 members"],
    priceId: "price_1NTLFXC13CZ7WmqGinBc0Hp2",
    price: 69,
    type: SubscriptionPlanType.large,
    billingPeriod: "monthly",
  },
  {
    name: "Large",
    features: ["Up to 50 members"],
    priceId: "price_1NTLFXC13CZ7WmqGioqjT00o",
    price: 768,
    type: SubscriptionPlanType.large,
    billingPeriod: "annually",
  },
]

export const TestSubscriptionPlans: ISubscriptionPlan[] = [
  {
    name: "Small",
    features: ["Up to 20 members"],
    priceId: "price_1NNDTwC13CZ7WmqGNbi6bhI2",
    price: 34,
    type: SubscriptionPlanType.small,
    billingPeriod: "monthly",
  },
  {
    name: "Small",
    features: ["Up to 20 members"],
    priceId: "price_1NNDUxC13CZ7WmqGR9yg4bRP",
    price: 348,
    type: SubscriptionPlanType.small,
    billingPeriod: "annually",
  },
  {
    name: "Medium",
    features: ["Up to 30 members"],
    priceId: "price_1NNDWWC13CZ7WmqGGlRpEZt0",
    price: 39,
    type: SubscriptionPlanType.medium,
    billingPeriod: "monthly",
  },
  {
    name: "Medium",
    features: ["Up to 30 members"],
    priceId: "price_1NNDWwC13CZ7WmqGabaS71qs",
    price: 408,
    type: SubscriptionPlanType.medium,
    billingPeriod: "annually",
  },
  {
    name: "Medium Large",
    features: ["Up to 50 members"],
    priceId: "price_1OQ4PiC13CZ7WmqGkDmuHIxj",
    price: 588,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: "annually",
  },
  {
    name: "Medium Large",
    features: ["Up to 50 members"],
    priceId: "price_1OQ4PVC13CZ7WmqG9TyL9tBg",
    price: 54,
    type: SubscriptionPlanType.mediumLarge,
    billingPeriod: "monthly",
  },
  {
    name: "Large",
    features: ["Unlimited members"],
    priceId: "price_1NNDXxC13CZ7WmqGaQHCnT8F",
    price: 69,
    type: SubscriptionPlanType.large,
    billingPeriod: "monthly",
  },
  {
    name: "Large",
    features: ["Unlimited members"],
    priceId: "price_1NNDYSC13CZ7WmqG92gBr40W",
    price: 768,
    type: SubscriptionPlanType.large,
    billingPeriod: "annually",
  },
]

export const SubscriptionPlans =
  process.env.NODE_ENV === "production"
    ? LiveSubscriptionPlans
    : TestSubscriptionPlans

export const IntroductionSteps = [
  {
    element: "#dashboard-welcome",
    intro: "Welcome to OneFile!",
    position: "bottom",
  },
  {
    element: "#dashboard-welcome",
    intro:
      "This is your dashboard. Here you can see your upcoming events, your upcoming tasks, and your upcoming appointments.",
    position: "bottom",
  },
  {
    element: "#dashboard-welcome",
    intro:
      "You can also see your upcoming events, your upcoming tasks, and your upcoming appointments.",
    position: "bottom",
  },
]

export const RosterIntroductionSteps = [
  {
    element: "#roster-welcome",
    intro: "Welcome to the one file roster!",
    position: "bottom",
  },
  {
    element: "#staff-members",
    intro:
      "This is your roster. Here you can see all of your staff members under all of your workspaces.",
    position: "bottom",
  },
  {
    element: "#staff-members",
    intro:
      "You can assign staff members to workspaces from the all staff members page.",
    position: "bottom",
  },
  {
    element: "#roster-container",
    intro:
      "This is your roster. Here you can click and drag on a row to create a time.",
    position: "bottom",
  },
  {
    element: "#roster-container",
    intro:
      "Timeslots can be dragged to change their start and end time.  These changes are inremented by 30 minutes.  You can click on a timeslot to edit to any minute value or remove the timeslot.",
    position: "bottom",
  },
  {
    element: "#save-button",
    intro: "Changes can be saved by clicking the save button.",
    position: "right",
  },
  {
    element: "#email-button",
    intro:
      "You can email your roster to your staff members by clicking the email button.",
    position: "top",
  },
  {
    element: "#add-staff-button",
    intro:
      "You can add a new staff member manually by clicking the add staff button.",
    position: "top",
  },
  {
    element: "#date-range-picker",
    intro: "You can select a date range to view your roster.",
    position: "top",
  },
]

export const DashboardIntroductionSteps = [
  {
    element: "#staff-welcome",
    intro:
      "Welcome to your OneFile dashboard.  This page gives you an overview of your organisation.",
    position: "bottom",
  },
  {
    element: "#workspaces",
    intro:
      "A workspace is a group of staff members.  It could be a room, a branch, or department of your business.  You can add a new workspace by clicking the add workspace button.",
  },
  {
    element: "#staff-members",
    intro:
      "This is your staff members page. Here you can see all of your staff members under all of your workspaces.",
    position: "bottom",
  },
  {
    element: "#rosters",
    intro:
      "You can create a roster for your staff members by clicking the create roster button.",
    position: "bottom",
  },
  {
    element: "#staff-checkin",
    intro:
      "You can use a sign in process to check in your staff members by going to Staff on site.  This will allow you to monitor hours and attendance.",
  },
]

export const TUTS = {
  DASHBOARD: DashboardIntroductionSteps,
}

export const fileIcons = [
  { name: "address-book", icon: "address-book.svg" },
  { name: "airplane", icon: "airplane.svg" },
  { name: "ambulance", icon: "ambulance.svg" },
  { name: "automobile", icon: "automobile.svg" },
  { name: "available", icon: "available.png" },
  { name: "bank", icon: "bank.svg" },
  { name: "beach", icon: "beach.svg" },
  { name: "bell", icon: "bell.png" },
  { name: "birthday-cake", icon: "birthday-cake.png" },
  { name: "book1", icon: "book1.svg" },
  { name: "bookmark", icon: "bookmark.svg" },
  { name: "briefcase", icon: "briefcase.svg" },
  { name: "british-breakfast", icon: "british-breakfast.svg" },
  { name: "bubble-comment", icon: "bubble-comment.svg" },
  { name: "builder", icon: "builder.svg" },
  { name: "bulb", icon: "bulb.svg" },
  { name: "bullhorn", icon: "bullhorn.svg" },
  { name: "bullseye", icon: "bullseye.svg" },
  { name: "bust", icon: "bust.svg" },
  { name: "busts", icon: "busts.svg" },
  { name: "cake", icon: "cake.svg" },
  { name: "cake2", icon: "cake2.svg" },
  { name: "calendar-date", icon: "calendar-date.svg" },
  { name: "calendar-reminder", icon: "calendar-reminder.svg" },
  { name: "card-file", icon: "card-file.svg" },
  { name: "card-index", icon: "card-index.svg" },
  { name: "chart-increase", icon: "chart-increase.svg" },
  { name: "chart-increasing", icon: "chart-increasing.svg" },
  { name: "chart", icon: "chart.svg" },
  { name: "checkout-list", icon: "checkout-list.svg" },
  { name: "classical-building", icon: "classical-building.svg" },
  { name: "clock-time", icon: "clock-time.svg" },
  { name: "clock", icon: "clock.png" },
  { name: "cog", icon: "cog.svg" },
  { name: "confetti", icon: "confetti.png" },
  { name: "construction", icon: "construction.svg" },
  { name: "credit-card", icon: "credit-card.svg" },
  { name: "diagram", icon: "diagram.svg" },
  { name: "disk", icon: "disk.svg" },
  { name: "doctor", icon: "doctor.svg" },
  { name: "file-storage", icon: "file-storage.png" },
  { name: "file", icon: "file.svg" },
  { name: "fixing-repair", icon: "fixing-repair.svg" },
  { name: "flag", icon: "flag.svg" },
  { name: "fuel", icon: "fuel.svg" },
  { name: "gift-memory", icon: "gift-memory.svg" },
  { name: "green-book", icon: "green-book.svg" },
  { name: "hammer", icon: "hammer.svg" },
  { name: "hat-birthday", icon: "hat-birthday.svg" },
  { name: "healthcare-hospital", icon: "healthcare-hospital.svg" },
  { name: "healthcare", icon: "healthcare.svg" },
  { name: "hourglass-time", icon: "hourglass-time.svg" },
  { name: "house-garden", icon: "house-garden.svg" },
  { name: "house", icon: "house.svg" },
  { name: "houses", icon: "houses.svg" },
  { name: "inbox", icon: "inbox.svg" },
  { name: "judge", icon: "judge.svg" },
  { name: "key", icon: "key.svg" },
  { name: "keyboard", icon: "keyboard.svg" },
  { name: "laptop", icon: "laptop.svg" },
  { name: "layers", icon: "layers.svg" },
  { name: "ledger", icon: "ledger.svg" },
  { name: "locked-pen", icon: "locked-pen.svg" },
  { name: "locked", icon: "locked.svg" },
  { name: "magnifying-glass", icon: "magnifying-glass.svg" },
  { name: "man-in-manual-wheelchair", icon: "man-in-manual-wheelchair.svg" },
  { name: "map", icon: "map.svg" },
  { name: "medical", icon: "medical.svg" },
  { name: "meeting", icon: "meeting.png" },
  { name: "memo", icon: "memo.svg" },
  { name: "menu", icon: "menu.svg" },
  { name: "money", icon: "money.svg" },
  { name: "mortarboard", icon: "mortarboard.svg" },
  { name: "office-building", icon: "office-building.svg" },
  { name: "outbox", icon: "outbox.svg" },
  { name: "package", icon: "package.svg" },
  { name: "page-facing-up", icon: "page-facing-up.svg" },
  { name: "page-with-curl", icon: "page-with-curl.svg" },
  { name: "paperclip", icon: "paperclip.svg" },
  { name: "party-hat", icon: "party-hat.png" },
  { name: "party-popper", icon: "party-popper.svg" },
  { name: "pen", icon: "pen.svg" },
  { name: "person-protection", icon: "person-protection.svg" },
  { name: "phone-receiver", icon: "phone-receiver.svg" },
  { name: "pill", icon: "pill.svg" },
  { name: "placard", icon: "placard.svg" },
  { name: "police-cap", icon: "police-cap.svg" },
  { name: "police-shield", icon: "police-shield.svg" },
  { name: "post-office", icon: "post-office.svg" },
  { name: "receipt", icon: "receipt.svg" },
  { name: "roster", icon: "roster.png" },
  { name: "round-pushpin", icon: "round-pushpin.svg" },
  { name: "schedule", icon: "schedule.png" },
  { name: "shield", icon: "shield.svg" },
  { name: "shirt", icon: "shirt.svg" },
  { name: "spiral-calendar-", icon: "spiral-calendar-.svg" },
  { name: "staff-on-site", icon: "staff-on-site.png" },
  { name: "star-prize", icon: "star-prize.svg" },
  { name: "store-verified", icon: "store-verified.svg" },
  { name: "student", icon: "student.svg" },
  { name: "telephone", icon: "telephone.svg" },
  { name: "testimonial", icon: "testimonial.svg" },
  { name: "thermometer", icon: "thermometer.svg" },
  { name: "ticket", icon: "ticket.svg" },
  { name: "ticket2", icon: "ticket2.svg" },
  { name: "traffic-lights", icon: "traffic-lights.svg" },
  { name: "triangle-measure", icon: "triangle-measure.svg" },
  { name: "unlocked", icon: "unlocked.svg" },
  { name: "warning", icon: "warning.svg" },
  { name: "woman-police-officer", icon: "woman-police-officer.svg" },
  { name: "world", icon: "world.svg" },
  { name: "world2", icon: "world2.svg" },
  { name: "writing", icon: "writing.svg" },
  { name: "alarm-clock-icon", icon: "alarm-clock-icon.svg" },
  { name: "clock-new", icon: "clock-new.svg" },
  { name: "electric-shock", icon: "electric-shock.svg" },
  { name: "food2", icon: "food2.svg" },
  { name: "life-vest", icon: "life-vest.svg" },
  { name: "school-bus", icon: "school-bus.svg" },
  { name: "user-agreement", icon: "user-agreement.svg" },
  { name: "approved-contract", icon: "approved-contract.svg" },
  { name: "construction", icon: "construction.svg" },
  { name: "emergency-exit", icon: "emergency-exit.svg" },
  { name: "fridge", icon: "fridge.svg" },
  { name: "medical-emergency", icon: "medical-emergency.svg" },
  { name: "warning", icon: "warning.svg" },
  { name: "auto", icon: "auto.svg" },
  { name: "contract-money", icon: "contract-money.svg" },
  { name: "exclamation", icon: "exclamation.svg" },
  { name: "graduation", icon: "graduation.svg" },
  { name: "medical-protection", icon: "medical-protection.svg" },
  { name: "swim-pool", icon: "swim-pool.svg" },
  { name: "wash-hands", icon: "wash-hands.svg" },
  { name: "ban", icon: "ban.svg" },
  { name: "corona-care", icon: "corona-care.svg" },
  { name: "fire-extinguisher", icon: "fire-extinguisher.svg" },
  { name: "hospital-location", icon: "hospital-location.svg" },
  { name: "medicine-jar", icon: "medicine-jar.svg" },
  { name: "taco", icon: "taco.svg" },
  { name: "winner-badge", icon: "winner-badge.svg" },
  { name: "barrier", icon: "barrier.svg" },
  { name: "court-gavil", icon: "court-gavil.svg" },
  { name: "fire", icon: "fire.svg" },
  { name: "hotdog", icon: "hotdog.svg" },
  { name: "order-police", icon: "order-police.svg" },
  { name: "time-punctual", icon: "time-punctual.svg" },
  { name: "wrist-watch", icon: "wrist-watch.svg" },
  { name: "basketball", icon: "basketball.svg" },
  { name: "cup", icon: "cup.svg" },
  { name: "float", icon: "float.svg" },
  { name: "human-resources", icon: "human-resources.svg" },
  { name: "party-flags", icon: "party-flags.svg" },
  { name: "toilet-male-female", icon: "toilet-male-female.svg" },
  { name: "blood-donation", icon: "blood-donation.svg" },
  { name: "danger-red-warning", icon: "danger-red-warning.svg" },
  { name: "follow-instructions", icon: "follow-instructions.svg" },
  { name: "knife", icon: "knife.svg" },
  { name: "police-report-court", icon: "police-report-court.svg" },
  { name: "toilet", icon: "toilet.svg" },
  { name: "bookmark", icon: "bookmark.svg" },
  { name: "death-danger", icon: "death-danger.svg" },
  { name: "food", icon: "food.svg" },
  { name: "legal", icon: "legal.svg" },
  { name: "safety-gloves", icon: "safety-gloves.svg" },

  { name: "new ambulance", icon: "ambulance-svgrepo-com.svg" },
  {
    name: "new balloon flat icon part 2",
    icon: "balloon-flat-icon-part-2-svgrepo-com.svg",
  },
  { name: "new bank card", icon: "bank-card-svgrepo-com.svg" },
  { name: "new bar graph", icon: "bar-graph-svgrepo-com.svg" },
  { name: "new blackboard", icon: "blackboard-svgrepo-com.svg" },
  { name: "new carrot", icon: "carrot-svgrepo-com.svg" },
  { name: "new cell phone", icon: "cell-phone-svgrepo-com.svg" },
  { name: "new cellphone", icon: "cellphone-svgrepo-com.svg" },
  {
    name: "new certificate award trophy",
    icon: "certificate-award-trophy-svgrepo-com.svg",
  },
  {
    name: "new certificate degree document",
    icon: "certificate-degree-document-svgrepo-com.svg",
  },
  { name: "new child", icon: "child-svgrepo-com.svg" },
  { name: "new coordinate", icon: "coordinate-svgrepo-com.svg" },
  { name: "new credit card", icon: "credit-card-svgrepo-com.svg" },
  { name: "new cup", icon: "cup-svgrepo-com.svg" },
  { name: "new data", icon: "data-svgrepo-com.svg" },
  {
    name: "new dollar bill part 2",
    icon: "dollar-bill-part-2-svgrepo-com.svg",
  },
  {
    name: "new drawer and038 document",
    icon: "drawer-and038-document-svgrepo-com.svg",
  },
  { name: "new electronic organ", icon: "electronic-organ-svgrepo-com.svg" },
  { name: "new expander", icon: "expander-svgrepo-com.svg" },
  { name: "new fighting", icon: "fighting-svgrepo-com.svg" },
  { name: "new fire", icon: "fire-svgrepo-com.svg" },
  { name: "new flash disk", icon: "flash-disk-svgrepo-com.svg" },
  {
    name: "new frying pan and fried egg",
    icon: "frying-pan-and-fried-egg-svgrepo-com.svg",
  },
  { name: "new golf hole", icon: "golf-hole-svgrepo-com.svg" },
  {
    name: "new greek parthenon style",
    icon: "greek-parthenon-style-svgrepo-com.svg",
  },
  { name: "new hamburger", icon: "hamburger-svgrepo-com.svg" },
  { name: "new handshake", icon: "handshake-svgrepo-com.svg" },
  { name: "new horn", icon: "horn-svgrepo-com.svg" },
  { name: "new icecream", icon: "icecream-svgrepo-com.svg" },
  {
    name: "new icon for s that can be used in business part 3",
    icon: "icon-for-s-that-can-be-used-in-business-part-3-svgrepo-com.svg",
  },
  { name: "new jump rope", icon: "jump-rope-svgrepo-com.svg" },
  { name: "new key", icon: "key-svgrepo-com.svg" },
  { name: "new life buoy", icon: "life-buoy-svgrepo-com.svg" },
  {
    name: "new medal gold winner 2",
    icon: "medal-gold-winner-2-svgrepo-com.svg",
  },
  { name: "new medal gold winner", icon: "medal-gold-winner-svgrepo-com.svg" },
  { name: "new music", icon: "music-svgrepo-com.svg" },
  { name: "new navigation", icon: "navigation-svgrepo-com.svg" },
  { name: "new notepad", icon: "notepad-svgrepo-com.svg" },
  {
    name: "new overseas college hat",
    icon: "overseas-college-hat-svgrepo-com.svg",
  },
  {
    name: "new physical education",
    icon: "physical-education-svgrepo-com.svg",
  },
  { name: "new pool", icon: "pool-svgrepo-com.svg" },
  { name: "new popcorn", icon: "popcorn-svgrepo-com.svg" },
  { name: "new product guide", icon: "product-guide-svgrepo-com.svg" },
  { name: "new puzzle", icon: "puzzle-svgrepo-com.svg" },
  {
    name: "new quality premium certificate",
    icon: "quality-premium-certificate-svgrepo-com.svg",
  },
  { name: "new radio", icon: "radio-svgrepo-com.svg" },
  { name: "new receipt part 2", icon: "receipt-part-2-svgrepo-com.svg" },
  { name: "new receipt", icon: "receipt-svgrepo-com.svg" },
  { name: "new record", icon: "record-svgrepo-com.svg" },
  { name: "new scoreboard", icon: "scoreboard-svgrepo-com.svg" },
  { name: "new screen computer", icon: "screen-computer-svgrepo-com.svg" },
  { name: "new sleeping", icon: "sleeping-svgrepo-com.svg" },
  { name: "new social contact", icon: "social-contact-svgrepo-com.svg" },
  { name: "new stadium horn", icon: "stadium-horn-svgrepo-com.svg" },
  { name: "new street sign", icon: "street-sign-svgrepo-com.svg" },
  { name: "new suitcase", icon: "suitcase-svgrepo-com.svg" },
  { name: "new telescope", icon: "telescope-svgrepo-com.svg" },
  { name: "new traffic sign stop", icon: "traffic-sign-stop-svgrepo-com.svg" },
  { name: "new tram", icon: "tram-svgrepo-com.svg" },
  {
    name: "new triangular display board",
    icon: "triangular-display-board-svgrepo-com.svg",
  },
  {
    name: "new trophy prize achievement",
    icon: "trophy-prize-achievement-svgrepo-com.svg",
  },
  {
    name: "new trophy prize medal 2",
    icon: "trophy-prize-medal-2-svgrepo-com.svg",
  },
  { name: "new watch", icon: "watch-svgrepo-com.svg" },
  { icon: "pie-dessert-food-svgrepo-com.svg", name: "pie-dessert-food" },
  { icon: "5211a-parking-svgrepo-com.svg", name: "5211a-parking" },
  { icon: "accessible-facility-svgrepo-com.svg", name: "accessible-facility" },
  { icon: "airplane-svgrepo-com.svg", name: "airplane" },
  { icon: "bicycle-svgrepo-com.svg", name: "bicycle" },
  { icon: "bread-svgrepo-com.svg", name: "bread" },
  { icon: "british-breakfast-svgrepo-com.svg", name: "british-breakfast" },
  { icon: "check-mark-svgrepo-com.svg", name: "check-mark" },
  {
    icon: "customer-care-hospital-2-svgrepo-com.svg",
    name: "customer-care-hospital-2",
  },
  {
    icon: "customer-support-chat-svgrepo-com.svg",
    name: "customer-support-chat",
  },
  { icon: "drinking-water-svgrepo-com.svg", name: "drinking-water" },
  { icon: "first-aid-svgrepo-com.svg", name: "first-aid" },
  { icon: "green-apple-svgrepo-com.svg", name: "green-apple" },
  { icon: "heart-svgrepo-com.svg", name: "heart" },
  {
    icon: "help-support-information-svgrepo-com.svg",
    name: "help-support-information",
  },
  { icon: "ice-cream-popsicle-svgrepo-com.svg", name: "ice-cream-popsicle" },
  { icon: "pencil-svgrepo-com.svg", name: "pencil" },
  {
    icon: "phone-call-telephone-svgrepo-com.svg",
    name: "phone-call-telephone",
  },
  { icon: "question-answer-svgrepo-com.svg", name: "question-answer" },
  { icon: "rainbow-svgrepo-com.svg", name: "rainbow" },
  { icon: "recycling-symbol-svgrepo-com.svg", name: "recycling-symbol" },
  { icon: "sandwich-svgrepo-com.svg", name: "sandwich" },
  { icon: "star-svgrepo-com.svg", name: "star" },
  { icon: "the-medical-svgrepo-com.svg", name: "the-medical" },
  {
    icon: "umbrella-with-rain-drops-svgrepo-com.svg",
    name: "umbrella-with-rain-drops",
  },
  { icon: "writing-hand-skin-1-svgrepo-com.svg", name: "writing-hand-skin-1" },
]

export interface IHomePageContent {
  banner: {
    image?: string
    heading: string
    blurb: string
  }
  features: {
    heading: string
    blurb: string
    items: string[]
  }[]
  contact: {
    heading?: string
    blurb?: string
    contactEmail?: string
    contactPhone?: string
  }
  testimonials: {
    heading: string
    blurb: string
    customer: string
  }
  whatWeDo: {
    heading: string
    blurb: string
  }
  whyChooseUs: {
    heading: string
    blurb: string
  }
  pricingPlans: {
    heading: string
    blurb: string
  }
}

export const HomePageContent: IHomePageContent = {}

export const rosterDefaultProperties = {
  businessHours: {
    start: new Date("2022-07-21T18:00:00.159Z"),
    end: new Date("2022-07-22T06:30:00.381Z"),
  },
  isEditableByManagers: false,
  nestStaffMembersInRooms: true,
  showAllspaces: true,
  showCalendarView: false,
  showDayView: false,
  showStaffMemberAvatars: true,
  showStaffMemberNames: true,
  showStaffMembersPosition: false,
  showTimeslotRange: true,
  showWeekView: false,
  useProfileColors: true,
  showOnlyBusinessHours: true,
}

export const DEFAULT_DASHBOARD_SETTINGS = {
  showCalendar: true,
  showExpiringFiles: true,
  showMyAvailability: true,
  showMyLeaveDates: true,
  showMySchedule: true,
  showNoticeBoard: true,
  showPendingLeaveRequests: true,
  showRecentMeetings: true,
  showStaffOnLeave: true,
  showStaffOnSite: true,
  showTimeTrackingTile: true,
  showOrganizationLogo: true,
  showTodaysRoster: true,
  showUpcomingBirthdays: true,
}

export const DEFAULT_GENERAL_FEATURE_SETTINGS = {
  feature_meeting: true,
  feature_leave_request: true,
  feature_sign_in_on_site: true,
  feature_performance_review: true,
  feature_roster: true,
  feature_time_tracker: true,
}

export const PermissionModules = [
  {
    name: "Meetings",
    description: "",
    permissions: [
      {
        permission: "meeting_can_create",
        label: "Create",
      },
      {
        permission: "meeting_can_view",
        label: "View",
      },
      {
        permission: "meeting_can_delete",
        label: "Delete",
      },
      {
        permission: "meeting_can_edit",
        label: "Edit",
      },
      {
        permission: "meeting_can_complete",
        label: "Complete",
      },
    ],
  },
  {
    name: "Time Tracker",
    permissions: [
      {
        permission: "time_tracker_can_view",
        label: "View",
      },
    ],
    onlyRoles: ["admin", "manager"],
  },
  {
    name: "Roster",
    permissions: [
      {
        permission: "roster_can_create",
        label: "Create",
      },
      {
        permission: "roster_can_view",
        label: "View",
      },
      {
        permission: "roster_can_edit",
        label: "Edit",
      },
      {
        permission: "roster_can_delete",
        label: "Delete",
      },
    ],
  },
  {
    name: `On site sign in's`,
    permissions: [
      {
        permission: "check_in_can_create",
        label: "Create",
      },
      {
        permission: "check_in_can_view",
        label: "View",
      },
      {
        permission: "check_in_can_edit",
        label: "Edit",
      },
      {
        permission: "check_in_can_delete",
        label: "Delete",
      },
    ],
  },
  // {
  // name: 'Library',
  // permissions: [{
  //     permission: 'library_can_create',
  //     label: 'Create'
  //   },
  //   {
  //     permission: 'library_can_view',
  //     label: 'View'
  //   },
  //   {
  //     permission: 'library_can_edit',
  //     label: 'Edit'
  //   },
  //   {
  //     permission: 'library_can_upload',
  //     label: 'Upload files'
  //   },
  //   {
  //     permission: 'library_can_delete',
  //     label: 'Delete files'
  //   },
  // ],
  // },
  // {
  // name: 'Dashboard',
  // permissions: [{
  //     permission: 'dashboard_can_view',
  //     label: 'View Dashboard'
  //   },
  //   {
  //     permission: 'dashboard_show_calendar',
  //     label: 'Show calendar'
  //   },
  //   {
  //     permission: 'dashboard_show_expiring_files',
  //     label: 'Show expiring files'
  //   },
  //   {
  //     permission: 'dashboard_show_my_availability',
  //     label: 'Show my availability'
  //   },
  //   {
  //     permission: 'dashboard_show_my_leave_dates',
  //     label: 'Show my leave dates'
  //   },
  //   {
  //     permission: 'dashboard_show_my_schedule',
  //     label: 'Show my schedule'
  //   },
  //   {
  //     permission: 'dashboard_show_notice_board',
  //     label: 'Show notice board'
  //   },
  //   {
  //     permission: 'dashboard_show_organization_logo',
  //     label: 'Show organization logo'
  //   },
  //   {
  //     permission: 'dashboard_show_pending_leave_requests',
  //     label: 'Show pending leave requests'
  //   },
  //   {
  //     permission: 'dashboard_show_recent_meetings',
  //     label: 'Show recent meetings'
  //   },
  //   {
  //     permission: 'dashboard_show_staff_on_leave',
  //     label: 'Show staff on leave'
  //   },
  //   {
  //     permission: 'dashboard_show_staff_on_site',
  //     label: 'Show staff on site'
  //   },
  //   {
  //     permission: 'dashboard_show_time_tracking_tile',
  //     label: 'Show time tracking tile'
  //   },
  //   {
  //     permission: 'dashboard_show_todays_Roster',
  //     label: 'Show todays roster'
  //   },
  //   {
  //     permission: 'dashboard_show_upcoming_birthdays',
  //     label: 'Show upcoming birthdays'
  //   },
  // ],
  // },
  {
    name: "Leave Requests",
    permissions: [
      {
        permission: "leave_requests_can_create",
        label: "Create",
      },
      {
        permission: "leave_requests_can_view",
        label: "View",
      },
      {
        permission: "leave_requests_can_delete",
        label: "Delete",
      },
      {
        permission: "leave_requests_can_edit",
        label: "Edit",
      },
      {
        permission: "leave_requests_can_approve",
        label: "Approve",
      },
    ],
    onlyRoles: ["admin", "manager"],
  },
  {
    name: "Performance Reviews",
    permissions: [
      {
        permission: "performance_reviews_can_create",
        label: "Create",
      },
      {
        permission: "performance_reviews_can_view",
        label: "View",
      },
      {
        permission: "performance_reviews_can_delete",
        label: "Delete",
      },
      {
        permission: "performance_reviews_can_edit",
        label: "Edit",
      },
      {
        permission: "performance_reviews_can_complete",
        label: "Complete",
      },
    ],
    onlyRoles: ["admin", "manager"],
  },
  //  {
  //   name: 'Tasks',
  //   permissions: [{
  //       permission: 'tasks_can_create',
  //       label: 'Create'
  //     },
  //     {
  //       permission: 'tasks_can_view',
  //       label: 'View'
  //     },
  //     {
  //       permission: 'tasks_can_delete',
  //       label: 'Delete'
  //     },
  //     {
  //       permission: 'tasks_can_edit',
  //       label: 'Edit'
  //     },
  //     {
  //       permission: 'tasks_can_complete',
  //       label: 'Complete'
  //     },
  //   ],
  // },
]
